<script>
import Header from '@components/header.vue'
// import Chat from '@components/chat.vue'
import Footer from '@components/footer-component.vue'
export default {
  components: {
    Header,
    // Chat,
    Footer,
  },
}
</script>

<template>
  <div class="main">
    <Header />
    <slot />
    <!-- <Chat /> -->
    <Footer />
  </div>
</template>

<style lang="scss" module>
@import '@design';
.main {
  position: relative;
}
</style>
