const partners = require('./partners.json')

export const state = {
  partners,
}

export const getters = {}

export const mutations = {
  SET_PARTNERS(state, partners) {
    state.partners = partners
  },
}

export const actions = {}
