<script>
export default {
  props: {
    show: {
      type: Boolean,
      // eslint-disable-next-line
      default: true,
    },
  },
}
</script>

<template>
  <div v-show="show" class="mb-5 social">
    <a
      href="https://www.facebook.com/xeniusconsulting/"
      target="_blank"
      class="social-item"
    >
      <font-awesome-icon
        :icon="['fab', 'facebook-f']"
        class="social-item-brand"
      ></font-awesome-icon>
    </a>
    <a
      href="https://www.linkedin.com/company/xeniusconsulting/"
      target="_blank"
      class="social-item"
    >
      <font-awesome-icon
        :icon="['fab', 'linkedin-in']"
        class="social-item-brand"
      ></font-awesome-icon>
    </a>
  </div>
</template>

<style lang="scss" scoped>
@import '@design';
.social {
  font-size: $size-font-bigger;

  @include respond-below(sm) {
    position: absolute;
    bottom: -50px;
    margin-bottom: 0 !important;
  }
  &-item {
    position: relative;
    display: inline-block;
    width: $size-font-large;
    height: $size-font-large;
    margin-right: 20px;
    background-color: white;
    border-radius: 50%;
    &:hover {
      background-color: $color-theme-green;
    }
    &-brand,
    &-brand:hover {
      position: absolute;
      top: 50%;
      left: 50%;
      color: black;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
