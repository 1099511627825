<script>
import appConfig from '@src/app.config'

export default {
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title
      return title ? `${title}` : appConfig.title
    },
  },
}
</script>

<template>
  <div id="app">
    <!--
        Even when routes use the same component, treat them
        as distinct and create the component again.
        -->
    <RouterView :key="$route.fullPath" />
  </div>
</template>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern

// Normalize default styles across browsers,
// https://necolas.github.io/normalize.css/
@import '~normalize.css/normalize.css';
// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import '~nprogress/nprogress.css';

// Design variables and utilities from src/design.
@import '@design';

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-color: $color-body-bg !important;
}

#app {
  @extend %typography-small;
}

// ===
// Base element styles
// ===

a,
a:visited,
a:hover {
  color: white;
  text-decoration: none;
}

a {
  &.link-underline {
    position: relative;
    &::after {
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 0;
      height: 1px;
      content: '';
      background-color: white;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      &::after {
        width: 100%;
      }
    }
  }
}

h1 {
  @extend %typography-xxlarge;
}

h2 {
  @extend %typography-xlarge;
}

h3 {
  @extend %typography-large;
}

h4 {
  @extend %typography-medium;
}

h5,
h6 {
  @extend %typography-small;
}

p {
  font-size: 14px;
}

// ===
// Vendor
// ===

#nprogress .bar {
  background: $color-link-text;
}

// ===
// welcome
// ===

// section {
//     margin-top: 80px;
//     margin-bottom: 80px;
// }

.padder-50 {
  padding: 50px 0;
}

.title-section {
  margin-bottom: 2px;
  font-size: 32px;

  @include respond-below(sm) {
    margin-bottom: 0;
    font-size: 20px;
  }
}
.title-section-desc {
  margin-bottom: 10px;
  font-size: 40px;
  line-height: 52px;

  @include respond-below(md) {
    font-size: 38px;
    line-height: 44px;
  }

  @include respond-below(sm) {
    margin-bottom: 10px;
    font-size: 34px;
    line-height: 40px;
  }

  @include respond-below(xs) {
    font-size: 32px;
    line-height: 36px;
  }
  &--small {
    font-size: 34px;

    @include respond-below(md) {
      font-size: 32px;
      line-height: 38px;
    }

    @include respond-below(sm) {
      font-size: 30px;
      line-height: 34px;
    }

    @include respond-below(xs) {
      font-size: 26px;
      line-height: 30px;
    }
  }
}
.title-article {
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: bold;
  line-height: 28px;

  @include respond-below(sm) {
    font-size: 24px;
    line-height: 24px;
  }

  @include respond-below(xs) {
    font-size: 20px;
    line-height: 20px;
  }
}
.title-article-date {
  margin-bottom: 0;
  font-size: 14px;

  @include respond-below(sm) {
    font-size: 12px;
  }
}

.text-color-orange-dark {
  color: $color-theme-orange-dark;
}

.text-color-orange {
  color: $color-theme-orange;
}

.text-color-green {
  color: $color-theme-green;
}

.text-color-pink {
  color: $color-theme-pink;
}
.text-color-red {
  color: $color-theme-red;
}
.text-color-purple {
  color: $color-theme-purple;
}
.bg-color-purple {
  background: $color-theme-purple;
}
.bg-color-orange-dark {
  background: $color-theme-orange-dark;
}

.bg-color-orange {
  background: $color-theme-orange;
}

.bg-color-red {
  background: $color-theme-red;
}

.bg-color-green {
  background: $color-theme-green;
}

.bg-color-blue {
  background: $color-theme-blue;
}

.bg-color-pink {
  background: $color-theme-pink;
}

.color-orange {
  color: $color-theme-orange-dark;
}

.bold {
  font-weight: 600;
}

.paragraph-limit {
  max-width: 30em;
}

.btn {
  position: relative;
  width: 14rem;
  height: auto;
  padding-left: 0;
  font-size: 13px;

  &:active,
  &:focus {
    box-shadow: none;
  }

  .button-text {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0.6rem 0;
    margin: 0;
    font-weight: 700;
    line-height: 2.4;
    color: $color-theme-white;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  }

  .circle {
    position: relative;
    display: block;
    width: 3rem;
    height: 2.5rem;
    margin: 0;
    border-radius: 1.625rem;
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  }

  &:hover {
    .circle {
      width: 105%;
    }
    .button-text {
      color: $color-theme-white;
    }
  }

  &--smaller {
    width: 9rem;
    .circle {
      width: 3.2rem;
    }
  }
}

.dropdown {
  .devis-caret {
    color: red;
    transition: all 0.2s ease-in-out;
  }
  &.show {
    .devis-caret {
      color: white;
      transform: rotate(180deg);
    }
  }
  .btn {
    width: 100%;
    padding: 5px 24px;
    color: $color-theme-white;
    text-align: left;
    border: 1px solid $color-theme-red;
    border-bottom: 1px solid $color-theme-red;
    border-radius: 50px;
  }

  .dropdown-menu {
    .dropdown-item {
      input {
        display: none;
      }
    }
  }

  &.show {
    background: $color-theme-red;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    .btn {
      border: none;
      box-shadow: none;
    }

    .dropdown-menu {
      z-index: 10;
      width: 100%;
      padding: 20px 0;
      margin: 0;
      background: $color-theme-red;
      border: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      &.show {
        border: none;
        .dropdown-item {
          width: 100%;
          color: $color-theme-white;
          &:hover {
            cursor: pointer;
            background-color: rgba($color: #000, $alpha: 0.5);
          }
        }
      }
    }
  }
  .checked {
    background-color: rgba($color: #000, $alpha: 0.3);
  }
}

// Header
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  width: 100%;
  padding: 30px 50px;
  transition: all 0.2s ease-in-out;
  &.bar {
    position: fixed;
    z-index: 10;
    height: 50px;
    background-color: rgba($color: #000, $alpha: 0.75);
    -webkit-box-shadow: 0 2px 2px 0 rgba(255, 255, 255, 0.3);
    -moz-box-shadow: 0 2px 2px 0 rgba(255, 255, 255, 0.3);
    box-shadow: 0 2px 2px 0 rgba(255, 255, 255, 0.3);
    .main-logo {
      z-index: 20;
      width: auto;
      height: 30px;
      transition: all 0.2s ease-in-out;
    }
  }
}

//Main Logo
.main-logo {
  z-index: 25;
  width: 160px;
  height: 90px;
  .logo {
    width: 100%;
    height: 100%;
  }
}

// Swiper
.slick-slider {
  .slick-dots {
    display: none;
  }
}
.m-t-80 {
  margin-top: 80px;
}
</style>
