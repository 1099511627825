<script>
export default {
  data() {
    return {
      routes: [
        // { title: 'Ce que nous faisons', path: '/projets' },
        { title: 'Qui sommes nous ?', path: '/nous-sommes' },
        { title: 'Nos Réalisations', path: '/projets' },
        { title: 'Entrer en contact', path: '/contact' },
      ],
      open: false,
    }
  },
  computed: {
    icon() {
      return this.open ? 'times' : 'bars'
    },
  },
  methods: {
    toggleMenu() {
      this.open = !this.open
      this.$emit('isMenuOpen', this.open)
    },
  },
}
</script>

<template>
  <div>
    <button
      :class="{ open }"
      class="menu-button shadow-none"
      @click="toggleMenu"
    >
      <font-awesome-icon :icon="icon"></font-awesome-icon>
    </button>
    <div v-show="open" class="menu">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="links">
              <ul>
                <li v-for="route in routes" :key="route.path" class="nav-item">
                  <h3 class="menu__text">
                    <a
                      :href="route.path"
                      class="nav-link"
                      active-class="active"
                      >{{ route.title }}</a
                    >
                  </h3>
                </li>
              </ul>
            </div>
          </div>
          <div class="d-none d-md-block col-12 col-lg-4 col-md-5">
            <div class="contact pl-4">
              <div>
                <!-- <h3 class="contact__country text-color-purple">
                  France
                </h3>
                <p class="address"
                  >Rue lac Huron, 1053 Les Berges du lac Tunis,<br />
                  Résidence la Brise 3ème étage
                </p>
                <p class="email">contact@xeniusconsulting.com</p>
                <h3 class="contact__phone">+216 58 238 322</h3> -->
              </div>
              <div>
                <h3 class="contact__country text-color-purple">Tunisie</h3>
                <p class="address">
                  Rue lac Huron, 1053 Les Berges du lac Tunis,<br />
                  Résidence la Brise 3ème étage
                </p>
                <p class="email">contact@xeniusconsulting.com</p>
                <h3 class="contact__phone">+216 58 238 322</h3>
              </div>
              <div class="mt-3 social">
                <a
                  href="https://www.facebook.com/xeniusconsulting/"
                  target="_blank"
                  class="social-item"
                >
                  <font-awesome-icon
                    :icon="['fab', 'facebook-f']"
                    class="social-item-brand"
                  ></font-awesome-icon>
                </a>
                <a
                  href="https://www.linkedin.com/company/xeniusconsulting/"
                  target="_blank"
                  class="social-item"
                >
                  <font-awesome-icon
                    :icon="['fab', 'linkedin-in']"
                    class="social-item-brand"
                  ></font-awesome-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@design';
.menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 600px;
  background-color: rgba($color: #000, $alpha: 0.9);
  // justify-content: center;
  &-button {
    z-index: 10;
    font-size: $size-font-bigger;
    color: white;
    background-color: transparent;
    border: none;
    outline: none;
    &.open {
      position: fixed;
      top: 15px;
      right: 52px;
    }
    &:hover {
      color: $color-theme-green;
    }
  }
  .links {
    padding-top: 50px;

    @include respond-below(md) {
      padding-top: 20px;
    }

    @include respond-below(sm) {
      padding-top: 0;
    }
    ul {
      list-style-type: none;
      &:hover {
        .menu__text {
          color: gray;

          @include respond-below(md) {
            font-size: 28px;
          }
        }
      }
      li {
        transition: all 0.2s ease-in-out;
        &:hover {
          padding-left: 5em;
          .menu__text {
            color: white;
          }
        }
        .menu__text {
          @include respond-below(md) {
            margin-bottom: 0;
            font-size: 24px;
          }
        }
      }
    }
  }
}

.contact {
  border-left: 2px solid white;
  p {
    @include respond-below(md) {
      margin-bottom: 5px;
    }
  }
  &__country {
    @include respond-below(md) {
      margin-bottom: 0;
      font-size: 26px;
    }
  }
  &__phone {
    font-weight: bold;

    @include respond-below(md) {
      margin-bottom: 0;
      font-size: 22px;
    }
  }
}

.social {
  font-size: $size-font-bigger;
  &-item {
    position: relative;
    display: inline-block;
    width: $size-font-large;
    height: $size-font-large;
    margin-right: 40px;
    background-color: white;
    border-radius: 50%;
    &:hover {
      background-color: $color-theme-green;
    }
    &-brand,
    &-brand:hover {
      position: absolute;
      top: 50%;
      left: 50%;
      color: black;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
