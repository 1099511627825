const projetDone = require('./projetDone.json')
const projetDoneFiltered = projetDone.filter(
  (projetdone) =>
    projetdone.name !== 'ISO-PG' &&
    projetdone.name !== 'ISO-OA' &&
    projetdone.name !== 'Coverus'
)
export const state = {
  projetDone,
  projetDoneFiltered,
}

export const getters = {}

export const mutations = {
  SET_PROJETDONE(state, projetDone) {
    state.projetDone = projetDone
  },
}

export const actions = {}
