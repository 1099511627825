const projects = require('./projects.json')
const projectsFiltered = projects.filter(
  (project) =>
    project.name !== 'ISO-PG' &&
    project.name !== 'ISO-OA' &&
    project.name !== 'Coverus'
)
export const state = {
  projects,
  projectsFiltered,
}

export const getters = {}

export const mutations = {
  SET_PROJECTS(state, projects) {
    state.projects = projects
  },
}

export const actions = {}
