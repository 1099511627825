<script>
export default {
  components: {},
}
</script>

<template>
  <div class="main-logo">
    <a href="/">
      <img src="@assets/images/logo.svg" alt="Xenius Logo" class="logo" />
    </a>
  </div>
</template>

<style lang="scss" module>
@import '@design';
</style>
