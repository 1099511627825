// modules imports

import Vue from 'vue'
import VueObjectFit from 'vue-object-fit'
import './plugins/fontawesome'
import VueMeta from 'vue-meta'

// css imports
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

// internal imports
import App from './app.vue'
import router from '@router'
import store from '@state/store'
import '@components/_globals'

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'
Vue.use(VueObjectFit)

// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = app
}

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
})
