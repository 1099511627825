<script>
import Menu from '@components/menu.vue'
import Logo from '@components/main-logo.vue'

const height = 40

export default {
  components: { Menu, Logo },
  data() {
    return {
      yOffset: window.scrollY,
      bar: false,
      menuIsOpen: false,
    }
  },
  watch: {
    yOffset(value) {
      this.bar = value > height && !this.menuIsOpen
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.yOffset = window.scrollY
    },
    setIsOpen(value) {
      this.menuIsOpen = value
      this.bar = this.yOffset > height && !this.menuIsOpen
    },
  },
}
</script>

<template>
  <div :class="{ bar }" class="header">
    <Logo />
    <Menu @isMenuOpen="setIsOpen" />
  </div>
</template>

<style lang="scss">
@import '@design';
</style>
