<script>
import SocialMedia from '@components/social-media.vue'

export default {
  components: { SocialMedia },
  computed: {
    show() {
      const blackList = ['contact']
      return !blackList.includes(this.$route.name)
    },
  },
}
</script>

<template>
  <section>
    <div
      :style="{
        backgroundImage:
          'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 50%, rgba(0,0,0,1) 100%), url(' +
          require('@assets/images/footer-bg.png') +
          ')',
      }"
      class="footer-section"
    >
      <div class="container py-5">
        <div class="row">
          <div class="col-md-auto order-md-1">
            <div v-show="show">
              <!-- <h3
                class="text-color-purple font-weight-bolder text-uppercase title-section"
              >
                Contactez-nous
              </h3> -->
              <!-- <h2 class="font-weight-bolder title-section-desc title-section-desc--small"> -->
              <!-- Alors, on bosse ensemble ! -->
              <!-- </h2> -->
              <!-- <button class="btn">
                <span class="circle bg-color-purple"> </span>
                <span class="button-text">Déposez votre candidature</span>
              </button> -->
            </div>
            <div v-show="!show" class="spacer"></div>
            <div class="addressContainer">
              <!-- <h3
                class="country text-color-purple font-weight-bolder text-uppercase title-section"
              >
                Tunisie
              </h3>
              <p class="address"
                >Rue lac Huron, 1053 Les Berges du lac Tunis,<br />
                Résidence la Brise 3ème étage
              </p>
              <p class="email">contact@xeniusconsulting.com</p>
              <h3 class="addressContainer__phone">+216 58 238 322</h3> -->
            </div>
          </div>
          <div
            class="col-md-8 order-md-0 d-flex flex-column justify-content-center"
          >
            <SocialMedia :show="show" />
            <div class="row">
              <div class="addressContainer col-md-6">
                <h3
                  class="country text-center text-md-left text-color-purple font-weight-bolder text-uppercase title-section"
                >
                  Tunisie
                </h3>
                <p class="address text-center text-md-left">
                  Résidence la Brise 3ème étage<br />
                  Rue lac Huron,<br />
                  1053 Les Berges du lac, Tunis
                </p>
                <p class="email text-center text-md-left"
                  >contact@xeniusconsulting.com</p
                >
                <h3 class="addressContainer__phone text-center text-md-left"
                  >+216 58 238 322</h3
                >
              </div>
              <div class="addressContainer col-md-6">
                <h3
                  class="country text-center text-md-left text-color-purple font-weight-bolder text-uppercase title-section"
                >
                  France
                </h3>
                <p class="address text-center text-md-left">
                  39 avenue Gambetta,<br />
                  <br />75020 Paris
                </p>
                <p class="email text-center text-md-left"
                  >contact@xeniusconsulting.com</p
                >
                <h3 class="addressContainer__phone text-center text-md-left"
                  >+33 (0)1 89 16 44 90
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center py-4">
      <img src="@assets/images/logo.svg" alt="Xenius Logo" />
      <small class="d-block mt-3">
        © Copyright 2020 XENIUS | Tous droits réservés.
      </small>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@design';
.footer-section {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.spacer {
  height: 60px;

  @include respond-below(sm) {
    height: 0;
  }
}
.addressContainer {
  &__phone {
    font-size: 22px;
    font-weight: 600;
  }
  p {
    margin-bottom: 5px;
  }
}
</style>
